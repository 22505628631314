












































































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { stakingServiceViewModel } from '../viewmodels/staking-service-viewmodel'

@Observer
@Component({
  components: {
    StakingCard: () => import('../components/staking-card.vue'),
  },
})
export default class StakingList extends Vue {
  vm = stakingServiceViewModel

  mounted() {
    this.vm.loadIfNeed()
  }
}
